import {configureStore} from "@reduxjs/toolkit"
import appReducer from "./gallerySlice";
import {galleriesApi} from "./galleriesApi";
import {setupListeners} from "@reduxjs/toolkit/query";

const store = configureStore({
    reducer: {
        [galleriesApi.reducerPath]: galleriesApi.reducer,
        app: appReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(galleriesApi.middleware)
})

setupListeners(store.dispatch)

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch