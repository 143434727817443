import {Reducer, useReducer} from "react";
import {Message} from "../model/ui";


type PopMessagesState = {
    messages: Array<Message>
}

enum ActionType {
    ADD, DISMISS
}

type PopMessageAction =
    { type: ActionType.ADD; payload: Message }
    | { type: ActionType.DISMISS; payload: number }

const initialState: PopMessagesState = {
    messages: []
}

const popMessageReducer = (state: PopMessagesState, action: PopMessageAction): PopMessagesState => {
    switch (action.type) {
        case ActionType.ADD:
            return {...state, messages: state.messages.concat(action.payload)}
        case ActionType.DISMISS:
            const messages = [...state.messages]
            const index = messages.findIndex(m => m.id === action.payload)
            if (index > -1) {
                messages[index].dismissed = true
            }
            return {...state, messages}
        default:
            return state
    }
}

const usePopMessages = () => {
    const [{messages}, dispatch] = useReducer<Reducer<PopMessagesState, PopMessageAction>>(popMessageReducer, initialState)

    const popMessage = (message: Message) => {
        dispatch({type: ActionType.ADD, payload: message})
    }

    const dismissMessage = (id: number) => {
        dispatch({type: ActionType.DISMISS, payload: id})
    }

    return {messages, popMessage, dismissMessage}
}

export default usePopMessages