import React, {useEffect} from 'react';
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import {activateMenu, setTotalCount} from "./reducer/gallerySlice";
import {useAppDispatch} from "./reducer/hooks";
import Main from "./components/layout/main";
import Loader from "./components/layout/loader";
import {useGetGalleries} from './reducer/galleriesApi';
import Modal from "./components/gallery/modal";
import useOfflinePopMessage from "./hooks/useOfflinePopMessage";
import useDeviceType from "./hooks/useDeviceType";


const App: React.FC = () => {
    useOfflinePopMessage()
    useDeviceType()
    const dispatch = useAppDispatch()
    const {data, isLoading, error} = useGetGalleries()

    useEffect(() => {
        if (!isLoading && data) {
            dispatch(setTotalCount(data.galleries.flatMap(g => g.collections).flatMap(c => c.entries).length))
            dispatch(activateMenu({gallery: data.galleries[0]}))
        }
        if (error) {
            console.error(error)
        }
    }, [dispatch, isLoading, data, error])


    return (
        <Loader>
            {data && (
                <>
                    <Header galleries={data.galleries}/>
                    <Main galleries={data.galleries}/>
                    <Footer/>
                    <Modal/>
                </>
            )}
        </Loader>
    );
}

export default App;
