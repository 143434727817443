import React from "react";
import {Gallery, GalleryResult} from "../../model/gallery";
import GalleryContainer from "../gallery/gallery";
import "./main.scss"

type MainProps = GalleryResult

const Main: React.FC<MainProps> = ({galleries}) => {
    return (
        <main>
            {galleries &&
            galleries.map((g: Gallery, i: number) => <GalleryContainer key={i} gallery={g}/>)}
        </main>
    )
}

export default Main
