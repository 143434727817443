import {useEffect, useState} from "react";

const useMediaQuery = (query: string): boolean => {
    const [match, setMatch] = useState<boolean>(false)

    useEffect(()=>{
        const queryList = window.matchMedia(query)
        const handler = () => setMatch(queryList.matches)

        try {
            queryList.addEventListener("change", handler, {passive: true})
        } catch (e) {
            queryList.addListener(handler)
        }

        handler()
        return () => {
            try {
                queryList.removeEventListener("change", handler)
            } catch (e) {
                queryList.removeListener(handler)
            }
        }
    }, [query])

    return match
}

export default useMediaQuery