import React from "react";
import {Collection, Gallery, getCollectionId} from "../../model/gallery";
import {ActivatePayload, selectMenu, toggleMenu} from "../../reducer/gallerySlice";
import {useAppDispatch} from "../../reducer/hooks";
import SubMenuItem from "./subMenuItem";

type MenuItemProps = {
    gallery: Gallery
    activeCollection: string | null
}

export type MenuHandlerProvider = (payload: ActivatePayload) => React.MouseEventHandler

const MenuItem: React.FC<MenuItemProps> = ({gallery, activeCollection}) => {
    const dispatch = useAppDispatch()

    const galleryClass = (gallery: Gallery) => {
        let result = "subnav"
        if (activeCollection != null && gallery.collections.map((c: Collection) => getCollectionId(gallery, c)).includes(activeCollection)) {
            result = `${result} active`
        }
        return result;
    }

    const handleMenuItem: MenuHandlerProvider = (payload: ActivatePayload) => (e: React.MouseEvent) => {
        e.preventDefault()
        dispatch(selectMenu(payload))
        if (payload.collection) {
            dispatch(toggleMenu(false))
        }
    }


    return (
        <div className={galleryClass(gallery)} key={gallery.type}>
            <button onClick={handleMenuItem({gallery})} onMouseDown={(e)=>{e.preventDefault()}} className={"subnavbtn"}>{gallery.label}</button>
            <div className={"subnav-content"}>
                {gallery.collections && gallery.collections.map((c: Collection) => <SubMenuItem key={c.type}
                                                                                                gallery={gallery}
                                                                                                collection={c}
                                                                                                activeCollection={activeCollection}
                                                                                                menuHandlerProvider={handleMenuItem}/>)}
            </div>
        </div>
    )

}

export default MenuItem