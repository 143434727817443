import React, {useCallback} from "react";
import "./progress.scss"
import {LoadProgress} from "../model/gallery";

type ProgressProps = {
    size?: string
    animationLength?: string
    state?: LoadProgress
}

const Progress: React.FC<ProgressProps> = ({size, animationLength, state}) => {
    const getClasses = useCallback<() => string>(() => {
        if (!state || state.total === 0) {
            return "progress indefinite"
        }
        return "progress"
    }, [state])

    const getStyle = useCallback<() => React.CSSProperties>(() => {
        let result: any = {}
        if (size) {
            result["--size"] = size
        }
        if (state && state.total > 0) {
            result["--progress"] = state.loaded / state.total
        }
        if (animationLength) {
            result["--animation-length"] = animationLength
        }
        return result as React.CSSProperties
    }, [size, state, animationLength])
    return <div className={getClasses()} style={getStyle()}/>
}

export default Progress