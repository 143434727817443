import React, {useContext} from "react";

import "./darkModeToggle.scss"
import DarkModeContext from "../../context/darkModeContext";


const DarkModeToggle: React.FC = () => {
    const {darkMode, setDarkMode} = useContext(DarkModeContext)

    const toggle = () => {
        setDarkMode?.(!darkMode)
    }

    return (
        <button className={"dark-mode-toggle"}
                title={darkMode ? "Világos mód" : "Sötét mód"}
                onClick={toggle}
                onMouseDown={(e)=>{e.preventDefault()}}>
            <i className={`fa fa-sun${darkMode ? " active":""}`}/>
            <i className={`fa fa-moon${!darkMode ? " active":""}`}/>
        </button>
    )

}

export default DarkModeToggle