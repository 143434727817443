import React from "react";
import PopMessage from "./popMessage";
import "./popMessages.scss"
import {usePopMessageContext} from "../../context/popMessageContext";

const PopMessages: React.FC = () => {
    const {messages} = usePopMessageContext()
    if (messages.length === 0) {
        return null
    }
    return (
        <div className={"pop-messages"}>
            {messages && messages.filter(m=>!m.dismissed).map(m=><PopMessage key={m.id} message={m}/>)}
        </div>
    )
}

export default PopMessages