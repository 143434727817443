import {useEffect, useState} from "react"


export const getOnlineStatus = () => {
    return typeof navigator !== "undefined" &&
        typeof navigator.onLine === "boolean" ? navigator.onLine : true
}

const useOnlineStatus = () => {
    const [online, setOnline] = useState<boolean>(getOnlineStatus())

    const goOnline = () => setOnline(true)
    const goOffline = () => setOnline(false)

    useEffect(() => {
        window.addEventListener("online", goOnline)
        window.addEventListener("offline", goOffline)
        return () => {
            window.removeEventListener("online", goOnline)
            window.removeEventListener("offline", goOffline)
        }
    }, [])

    return online
}

export default useOnlineStatus