import React from "react";
import {Collection, Gallery} from "../../model/gallery"
import CollectionContainer from "./collection";
import "./gallery.scss"

type GalleryProps = {
    gallery: Gallery
}

const GalleryContainer: React.FC<GalleryProps> = ({gallery}) => {

    return (
        <section id={gallery.type}>
            {gallery.collections &&
            gallery.collections.map((c: Collection)=><CollectionContainer key={c.type} gallery={gallery} collection={c}/>)}
        </section>
    )
}

export default GalleryContainer

