import React, {createContext, useContext} from "react";
import usePopMessages from "../hooks/usePopMessages";
import {CreateMessageOptions, Message, MessageOptions} from "../model/ui";
import PopMessages from "../components/layout/popMessages";

type PopMessageContextProps = {
    messages: Array<Message>
    pop: (message: CreateMessageOptions) => void
    dismiss: (id: number) => void
}

const PopMessageContext = createContext<PopMessageContextProps | undefined>(undefined)

type PopMessageProviderProps = {
    options?: MessageOptions
}

export const PopMessageProvider: React.FC<PopMessageProviderProps> = ({options, children}) => {
    const {messages, popMessage, dismissMessage} = usePopMessages()

    const pop = (message: CreateMessageOptions) => {
        popMessage({
            id: Date.now() * Math.random(),
            severity: "INFO",
            dismissed: false,
            dismissible: true,
            timestamp: Date.now(),
            ...options,
            ...message
        })
    }

    const dismiss = (id: number) => {
        dismissMessage(id)
    }

    return (
        <PopMessageContext.Provider value={{messages, pop, dismiss}}>
            {children}
            <PopMessages/>
        </PopMessageContext.Provider>
    )

}

export const usePopMessageContext = (): PopMessageContextProps => {
    const context = useContext(PopMessageContext)
    if (context === undefined) {
        throw new Error("usePopMessageContext must be within PopMessageContext")
    }
    return context
}

export const usePop = () => usePopMessageContext().pop