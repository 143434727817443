import React, {useRef} from "react";
import {Collection, Gallery, getCollectionId} from "../../model/gallery";
import {MenuHandlerProvider} from "./menuItem";

type SubMenuItemProps = {
    gallery: Gallery,
    collection: Collection,
    activeCollection: string | null,
    menuHandlerProvider: MenuHandlerProvider
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({gallery, collection, activeCollection, menuHandlerProvider}) => {

    const ref = useRef<HTMLButtonElement>(null)

    const getClasses = () => {
        let classes
        if (getCollectionId(gallery, collection) === activeCollection) {
            classes = "active"
        }
        return classes
    }

    const menuHandler = () => {
        return menuHandlerProvider({gallery, collection})
    }

    return (
        <button ref={ref} onClick={menuHandler()} onMouseDown={(e)=> {e.preventDefault()}}  className={getClasses()}>{collection.label}</button>
    )
}

export default SubMenuItem