import React, {useCallback, useEffect, useState} from "react";

export const useLocalStorage = <T>(key: string, defaultValue?: T | (() => T)) => {
    return useStorage({key, defaultValue, storage: window.localStorage})
}

export const useSessionStorage = <T>(key: string, defaultValue?: T | (() => T)) => {
    return useStorage({key, defaultValue, storage: window.sessionStorage})
}

type StorageOptions<T> = {
    key: string
    defaultValue?: T | (() => T)
    storage: Storage
}

type StorageResult<T> = [
    value: T | undefined,
    setValue: React.Dispatch<React.SetStateAction<T | undefined>>,
    remove: () => void,
]

const useStorage = <T>({key, defaultValue, storage}: StorageOptions<T>): StorageResult<T> => {
    const [value, setValue] = useState<T | undefined>(()=>{
        const jsonValue = storage.getItem(key)
        if (jsonValue != null) return JSON.parse(jsonValue)

        if (defaultValue instanceof Function) return defaultValue()
        return defaultValue
    })

    useEffect(()=>{
        if (value === undefined) return storage.removeItem(key)
        storage.setItem(key, JSON.stringify(value))
    }, [key, value, storage])

    const remove = useCallback(()=>{
        setValue(undefined)
    }, [])

    return [value, setValue, remove]
}

export default useLocalStorage