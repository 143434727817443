import {useEffect, useState} from "react";

const useScrollTop = (): number => {
    const [scrollTop, setScrollTop] = useState(0)

    useEffect(()=>{
        const update = () => {
            setScrollTop(window.scrollY)
        }
        window.addEventListener("scroll", update, {passive: true})
        update()
        return ()=> window.removeEventListener("scroll", update)
    }, [])

    return scrollTop
}

export default useScrollTop