import React from "react";
import "./logo.scss"

const Logo: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="logo" viewBox="0 0 100 100">
            <rect className="fill-primary" width="100" height="100"/>
            <polygon className="fill-secondary"
                     points="32.44,75 32.44,54.26 43.4,54.26 43.4,57.64 36.01,57.64 36.01,61.03 41.03,61.03 41.03,64.28 36.03,64.28 36.03,75 "/>
            <path className="fill-secondary"
                  d="M56.35,54.26v9.93c0,1.94-0.74,2.91-3.06,2.91c-2.32,0-3.06-0.97-3.06-2.91v-9.93h-3.57v10.23 c0,4.02,2.18,6.14,6.63,6.14s6.63-2.12,6.63-6.14V54.26H56.35z"/>
            <polygon className="fill-secondary"
                     points="77.86,70.26 77.86,54.26 81.43,54.26 81.43,66.85 89.92,66.85 89.92,70.26 "/>
            <polygon className="fill-secondary"
                     points="63.81,70.26 63.81,54.26 67.37,54.26 67.37,66.85 75.29,66.85 75.29,70.26 "/>
            <path className="fill-secondary"
                  d="M10,90V73.99h5.8c4.81,0,8.04,3.07,8.04,8.01c0,4.94-3.23,7.99-8.06,7.99H10z M15.64,86.64 c2.46,0,4.47-1.42,4.47-4.63c0-3.21-2.01-4.65-4.47-4.65h-2.08v9.28H15.64z"/>
            <path className="fill-secondary"
                  d="M34.28,73.66c4.49,0,8.31,3.27,8.31,8.35c0,5.06-3.81,8.33-8.31,8.33c-4.51,0-8.33-3.27-8.33-8.33 C25.95,76.93,29.77,73.66,34.28,73.66 M34.28,86.82c2.21,0,4.6-1.49,4.6-4.83c0-3.34-2.39-4.85-4.6-4.85 c-2.23,0-4.63,1.51-4.63,4.85C29.65,85.33,32.05,86.82,34.28,86.82"/>
            <path className="fill-secondary"
                  d="M52.97,73.66c4.49,0,8.31,3.27,8.31,8.35c0,5.06-3.82,8.33-8.31,8.33c-4.52,0-8.33-3.27-8.33-8.33 C44.64,76.93,48.46,73.66,52.97,73.66 M52.97,86.82c2.21,0,4.6-1.49,4.6-4.83c0-3.34-2.39-4.85-4.6-4.85 c-2.23,0-4.63,1.51-4.63,4.85C48.35,85.33,50.74,86.82,52.97,86.82"/>
            <path className="fill-secondary"
                  d="M68.5,84.04h-1.12V90h-3.57V73.99h6.2c3.16,0,5.19,2.17,5.19,5.01c0,2.17-1.2,3.81-3.16,4.51L75.29,90h-3.93 L68.5,84.04z M69.33,80.97c1.47,0,2.26-0.81,2.26-1.94c0-1.2-0.79-1.94-2.26-1.94h-1.96v3.88H69.33z"/>
            <path className="fill-secondary"
                  d="M86.68,78.83c-0.11-0.75-0.72-2.01-2.66-2.01c-1.33,0-2.19,0.84-2.19,1.72c0,0.77,0.47,1.33,1.58,1.53 l2.15,0.41c3.05,0.56,4.63,2.53,4.63,4.81c0,2.51-2.1,5.06-5.91,5.06c-4.43,0-6.32-2.87-6.55-5.12l3.27-0.79 c0.14,1.47,1.13,2.75,3.32,2.75c1.4,0,2.26-0.68,2.26-1.67c0-0.81-0.65-1.4-1.72-1.6l-2.19-0.41c-2.71-0.52-4.36-2.33-4.36-4.7 c0-3.03,2.66-5.15,5.73-5.15c4,0,5.51,2.39,5.85,4.27L86.68,78.83z"/>
        </svg>
    )
}

export default Logo