import {useEffect, useState} from "react";
import useOnlineStatus from "./useOnlineStatus";
import {usePop} from "../context/popMessageContext";

const useOfflinePopMessage = (): boolean => {
    const onlineStatus = useOnlineStatus()
    const pop = usePop()
    const [wasOffline, setWasOffline] = useState<boolean>(false)

    useEffect(()=>{
        if (!onlineStatus && !wasOffline) {
            pop({
                severity: "ERROR",
                text: "Kapcsolat megszakadt",
                icon: "fa fa-unlink"
            })
            setWasOffline(true)
        }
        if (onlineStatus && wasOffline) {
            pop({
                severity: "SUCCESS",
                text: "Kapcsolat helyreállt",
                icon: "fa fa-link"
            })
            setWasOffline(false)
        }
        return () => {
            setWasOffline(false)
        }
    }, /* eslint-disable react-hooks/exhaustive-deps */[onlineStatus])

    return !onlineStatus
}

export default useOfflinePopMessage