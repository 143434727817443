import React, {useEffect, useRef, useState} from "react";
import {GalleryEntry} from "../../model/gallery";
import "./modalImage.scss"
import Progress from "../progress";
import {useAppSelector} from "../../reducer/hooks";
import {selectCollapsed} from "../../reducer/gallerySlice";
import ModalNavButton from "./modalNav";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import {ModalEventHandler} from "./modal";
import {usePop} from "../../context/popMessageContext";

type ModalImageProps = {
    entry: GalleryEntry|null,
    handleNext: ModalEventHandler,
    handlePrevious: ModalEventHandler,
    handleClose: ModalEventHandler,
}


const ModalImage: React.FC<ModalImageProps> = ({entry, handleNext, handlePrevious, handleClose}) => {
    const [toggled, setToggled] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const imgRef = useRef<HTMLImageElement>(null)
    const collapsed = useAppSelector(selectCollapsed)
    const onlineStatus = useOnlineStatus()
    const pop = usePop()

    const getHandler = () => {
        return collapsed ? handleClose : ()=>{}
    }

    const toggleImage = (e: React.MouseEvent) => {
        e.preventDefault()
        setToggled(!toggled)
        setLoaded(false)
    }

    const loadHandler = () => {
        setLoaded(true)
    }

    const errorHandler = ()=>{
        if (!onlineStatus) {
            pop({
                severity: "ERROR",
                text: "Nincs kapcsolat",
                icon: "fa fa-unlink",
                timeout: 7000
            })
        } else {
            pop({
                severity: "ERROR",
                text: "Kép nem található",
                timeout: 7000
            })
        }
        if (!toggled) {
            handleClose()
        } else {
            setToggled(false)
        }
    }

    useEffect(() => {
        if (imgRef.current?.complete) return
        setLoaded(false)
        setToggled(false)
    }, [entry, imgRef])

    useEffect(() => {
        const img = imgRef.current
        if (img == null) return
        img.style.aspectRatio = `${img.naturalWidth}/${img.naturalHeight}`
    }, [imgRef])

    if (!entry) return null

    return (
        <div className={"modal-image-wrapper"} style={loaded ? {} : {justifyContent: "center"}}>
            {loaded && <ModalNavButton handler={handlePrevious} icon={"fa fa-chevron-left"} position={"left"} title={"Előző"}/>}
            <img ref={imgRef}
                 src={`${process.env.REACT_APP_BACKEND_URL}${toggled ? entry.toggledPath : entry.path}`}
                 alt={entry.alt} onClick={getHandler()}
                 style={{display: loaded ? "block" : "none"}}
                 onLoad={loadHandler} onError={errorHandler}/>
            {loaded && <ModalNavButton handler={handleNext} icon={"fa fa-chevron-right"} position={"right"} title={"Következő"}/>}
            {!loaded && <Progress/>}
            {loaded && (
            <div className={"footer"}>
                {entry.toggledPath && (
                    <div className={"toggle"} title={`${toggled ? "Csukj be!" : "Nyiss ki!"}`}>
                        <button onClick={toggleImage}><i
                            className={`fa fa-beat ${toggled ? "fa-door-closed" : "fa-door-open"}`}/></button>
                    </div>
                )}
                <div className={"title"}>
                    <span>{entry.note && <span className={"modal-badge"} data-badge-type={entry.note}>{entry.noteTitle}</span>}{entry.title}</span>
                    <span>{entry.desc}</span>
                </div>
                {entry.logo && (
                    <div className={"logo"}>
                        <img src={`${process.env.REACT_APP_BACKEND_URL}${entry.logo}`} alt={entry.logo}/>
                    </div>
                )}
            </div>
            )}
        </div>
    )
}

export default ModalImage