import React, {useEffect, useRef} from "react";
import {Collection, Gallery, GalleryEntry, getCollectionId} from "../../model/gallery"
import GalleryEntryContainer from "./entry";
import "./collection.scss"
import useScrollTop from "../../hooks/useScrollTop";
import {useAppDispatch, useAppSelector} from "../../reducer/hooks";
import {activateMenu, selectAutoScrolling, scrollFinished, selectActiveCollection} from "../../reducer/gallerySlice";
import useScrollFinish from "../../hooks/useScrollFinish";

type CollectionProps = {
    gallery: Gallery
    collection: Collection
}

const CollectionContainer: React.FC<CollectionProps> = ({gallery, collection}) => {

    const ref = useRef<HTMLDivElement>(null)
    const scrollTop = useScrollTop()
    const activeCollection = useAppSelector(selectActiveCollection)
    const autoScrolling = useAppSelector(selectAutoScrolling)

    const dispatch = useAppDispatch()
    const {setListen} = useScrollFinish(() => dispatch(scrollFinished()), {unchangedFrames: 5})

    useEffect(() => {
        if (ref.current && ref.current.offsetTop + ref.current.offsetHeight > scrollTop + 400 && scrollTop >= ref.current.offsetTop - 400) {
            dispatch(activateMenu({gallery, collection}))
            return () => {
            }
        }
    }, [scrollTop, dispatch, ref, gallery, collection])

    useEffect(() => {
        if (ref.current && autoScrolling && activeCollection === getCollectionId(gallery, collection)) {
            setListen(true)
            window.scrollTo({top: ref.current.offsetTop - 70, behavior: "smooth"})
        }
    }, [autoScrolling, activeCollection, setListen, gallery, collection])

    return (
        <div ref={ref} id={getCollectionId(gallery, collection)} className={"sub-segment"}>
            <div className={`segment-title`}>
                <div className={"text"}>{gallery.label} / {collection.label}</div>
                <div className={"line"}/>
            </div>
            <div className={"lightboxgallery-gallery clearfix"}>
                {collection.entries &&
                collection.entries.map((e: GalleryEntry, i: number) => <GalleryEntryContainer key={i} gallery={gallery}
                                                                                              collection={collection}
                                                                                              entry={e}/>)}
            </div>
        </div>
    )
}

export default CollectionContainer