import React, {useEffect, useState} from "react";
import {GalleryEntry} from "../../model/gallery";
import {useAppDispatch, useAppSelector} from "../../reducer/hooks";
import {closeEntry, nextEntry, previousEntry, selectOpenedEntry} from "../../reducer/gallerySlice";
import ModalImage from "./modalImage";
import "./modal.scss"
import {useSwipeable} from "react-swipeable";
import {useKeyboard} from "react-keyboard-event";

type ModalEvent = React.MouseEvent | React.KeyboardEvent | React.TouchEvent
export type ModalEventHandler = (e?: ModalEvent) => void

const Modal: React.FC = () => {
    const entry: GalleryEntry | null = useAppSelector(selectOpenedEntry)
    const [render, setRender] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const [modalClasses, setModalClasses] = useState<string>("modal hide")

    useKeyboard({
        listeners: {
            "ArrowLeft": () => dispatch(previousEntry()),
            "ArrowRight": () => dispatch(nextEntry()),
            "Escape": () => dispatch(closeEntry())
        },
        shouldListen: render
    })

    const swipeHandlers = useSwipeable({
        preventDefaultTouchmoveEvent: true,
        onSwiped: (eventData => {
            if (eventData.dir === "Right") {
                dispatch(previousEntry())
            } else if (eventData.dir === "Left") {
                dispatch(nextEntry())
            }
        }),
        onSwiping: (eventData) => {
            if (eventData.dir === "Right") {
                setModalClasses("modal tilt-right")
            } else if (eventData.dir === "Left") {
                setModalClasses("modal tilt-left")
            } else {
                setModalClasses("modal")
            }
        }
    })

    const handleNext: ModalEventHandler = (e?: ModalEvent) => {
        e?.preventDefault()
        dispatch(nextEntry())
    }

    const handlePrevious: ModalEventHandler = (e?: ModalEvent) => {
        e?.preventDefault()
        dispatch(previousEntry())
    }

    const handleClose: ModalEventHandler = (e?: ModalEvent) => {
        e?.preventDefault()
        dispatch(closeEntry())
    }

    useEffect(() => {
        if (entry != null) {
            document.body.classList.add("modal-open")
            setModalClasses("modal")
            setRender(true)
        } else {
            document.body.classList.remove("modal-open")
            setModalClasses("modal hide")
            setTimeout(() => setRender(false), 350)
        }
    }, [entry])

    if (!render) {
        return null
    }


    return (
        <div className={modalClasses} {...swipeHandlers}>
            <div className={"backdrop"} onClick={handleClose}/>
            <ModalImage entry={entry} handleNext={handleNext} handleClose={handleClose}
                        handlePrevious={handlePrevious}/>
        </div>
    )


}

export default Modal