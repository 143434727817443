import React, {useCallback, useState} from "react";
import useAsync from "./useAsync";

type ScrollFinishResult = {
    listen: boolean,
    setListen: React.Dispatch<React.SetStateAction<boolean>>
}

type ScrollFinishOptions = {
    unchangedFrames?: number,
    listenOnStart?: boolean
}

const defaultOptions = {
    unchangedFrames: 20,
    listenOnStart: false
}

const useScrollFinish = (callback: () => void, options: ScrollFinishOptions = defaultOptions): ScrollFinishResult => {
    const [listen, setListen] = useState<boolean>(options.listenOnStart ?? defaultOptions.listenOnStart)
    const memCallback = useCallback(() => callback?.(), [callback])

    useAsync(() => {
        if (!listen) return Promise.resolve()
        let lastChangedFrame = 0
        let lastX = window.scrollX
        let lastY = window.scrollY

        return new Promise<void>(() => {
            const tick = (frames: number) => {
                if (frames >= 500 || frames - lastChangedFrame > (options.unchangedFrames ?? defaultOptions.unchangedFrames)) {
                    setListen(false)
                    memCallback()
                } else {
                    if (window.scrollX !== lastX || window.scrollY !== lastY) {
                        lastChangedFrame = frames
                        lastX = window.scrollX
                        lastY = window.scrollY
                    }
                    window.requestAnimationFrame(tick.bind(null, frames + 1))
                }
            }
            tick(0)
        })
    }, [listen])

    return { listen, setListen }
}

export default useScrollFinish