import {DependencyList, useCallback, useEffect, useState} from "react";

type AsyncResult<T> = {
    loading: boolean,
    error?: Error,
    value?: T
}

const useAsync = <T>(promise: () => Promise<T>, deps: DependencyList): AsyncResult<T> => {
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error>()
    const [value, setValue] = useState<T>()

    const callbackMemoized = useCallback(() => {
        setLoading(true)
        setError(undefined)
        setValue(undefined)
        promise()
            .then(setValue)
            .catch(setError)
            .finally(() => setLoading(false))

    }, /* eslint-disable react-hooks/exhaustive-deps */ deps)

    useEffect(() => {
        callbackMemoized()
    }, [callbackMemoized])
    return {loading, error, value}
}

export default useAsync