import React, {useLayoutEffect, useState} from "react";
import "./loader.scss"
import Progress from "../progress";
import {useAppSelector} from "../../reducer/hooks";
import {selectLoadProgress} from "../../reducer/gallerySlice";


const Loader: React.FC = ({children}) => {
    const loadProgress = useAppSelector(selectLoadProgress)
    const [render, setRender] = useState<boolean>(true)
    const [classes, setClasses] = useState<string>("")

    useLayoutEffect(()=>{
        if (loadProgress && loadProgress.total > 0 && loadProgress.loaded === loadProgress.total) {
            document.body.classList.remove("loading")
            setClasses("hide")
            setTimeout(()=> {
                setRender(false)
            }, 200)
        }
    }, [loadProgress])

    return (
        <>
            {render && (
                <div id="cover" className={classes}>
                    <Progress state={loadProgress} size={"10rem"}/>
                </div>
            )}
            {children}
        </>
    )
}

export default Loader