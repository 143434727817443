import React from "react";
import "./modalNav.scss"

type ModalNavButtonProps = {
    handler: React.EventHandler<any>,
    icon: string,
    position: "left" | "right",
    title?: string
}

const ModalNavButton: React.FC<ModalNavButtonProps> = ({handler, icon, position, title}) => {

    return (
        <div className={`modal-nav-button ${position}`} title={title}>
            <button onClick={handler} onMouseDown={e => {e.preventDefault()}}><i
                className={`${icon} ${position}`}/></button>
        </div>

    )
}

export default ModalNavButton