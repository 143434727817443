import React, {useCallback, useEffect, useState} from "react";
import {Message} from "../../model/ui";
import "./popMessage.scss"
import {useSwipeable} from "react-swipeable";
import {usePopMessageContext} from "../../context/popMessageContext";

type PopMessageProps = {
    message: Message
}

const PopMessage: React.FC<PopMessageProps> = ({message}) => {

    const {dismiss} = usePopMessageContext()

    const [classes, setClasses] = useState<string>(`pop-message${message.timeout ? " timeout" : ""}`)
    const [styles, setStyles] = useState<React.CSSProperties>({})
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()


    const timeoutCallback = useCallback(() => {
        dismiss(message.id)
        if (timeoutId) {
            clearTimeout(timeoutId)
            setTimeoutId(undefined)
        }
    }, [timeoutId, message.id, dismiss])


    const handleDismiss = useCallback(() => {
        setClasses(`pop-message${message.timeout ? " timeout" : ""} hide`)
        setTimeout(timeoutCallback, 150)

    }, [timeoutCallback, message.timeout])

    const swipeHandlers = useSwipeable({
        onSwipedRight: () => {
            if (message.dismissible) {
                setClasses(`pop-message${message.timeout ? " timeout" : ""} hide slide-right`)
                setTimeout(timeoutCallback, 150)
            }
        }
    })

    useEffect(() => {
        if (message.timeout) {
            setStyles({"--length-timeout": message.timeout} as React.CSSProperties)
            setTimeoutId(setTimeout(handleDismiss, message.timeout))
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
                setTimeoutId(undefined)
            }
        }
    }, /* eslint-disable react-hooks/exhaustive-deps */[])

    const renderActionButton = useCallback((): JSX.Element | null => {
        if (!message.button) {
            return null;
        }
        return (<button className={"action-button"} onClick={message.button.callback}>{message.button.text}</button>)
    }, [message])

    const renderDismissButton = useCallback((): JSX.Element | null => {
        if (!message.dismissible) {
            return null;
        }
        return (<button className={"dismiss-button"} onClick={handleDismiss}><i className={"fa fa-times"}/></button>)
    }, [message])

    return (
        <div className={classes} style={styles} data-severity={message.severity} {...swipeHandlers}>
            {message.icon && <i className={message.icon}/>}
            <span>{message.text}</span>
            {renderActionButton()}
            {renderDismissButton()}
        </div>
    )
}

export default PopMessage