import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import store from "./reducer/store";
import {Provider} from "react-redux";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import smoothscroll from 'smoothscroll-polyfill'
import {DarkModeProvider} from "./context/darkModeContext";
import {PopMessageProvider} from "./context/popMessageContext";

smoothscroll.polyfill()

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <DarkModeProvider>
                <PopMessageProvider options={{timeout: 7000}}>
                    <App/>
                </PopMessageProvider>
            </DarkModeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();