import {useEffect} from "react";
import {isMobile, isTablet, isDesktop, useMobileOrientation} from "react-device-detect";


const useDeviceType = () => {

    const {isPortrait, isLandscape, orientation} = useMobileOrientation()

    useEffect(() => {
        if (isTablet) {
            document.body.classList.add("tablet")
        } else if (isMobile) {
            document.body.classList.add("mobile")
        }
    }, [])
    return {isMobile, isTablet, isDesktop, isPortrait, isLandscape, orientation}
}

export default useDeviceType