import React from "react";
import useDarkMode from "../hooks/useDarkMode";

type DarkModeContextProps = {
    darkMode: boolean
    setDarkMode?: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const defaultState: DarkModeContextProps = {
    darkMode: false
}

const DarkModeContext = React.createContext<DarkModeContextProps>(defaultState)

export default DarkModeContext

export const DarkModeProvider: React.FC = ({children}) => {
    const [darkMode, setDarkMode] = useDarkMode()

    return (
        <DarkModeContext.Provider value={{darkMode, setDarkMode}}>
            {children}
        </DarkModeContext.Provider>

    )
}