import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Collection, Gallery, GalleryEntry, getCollectionId, LoadProgress} from "../model/gallery";
import {RootState} from "./store";
import {isDesktop} from "react-device-detect";

interface AppState {
    autoScrolling: boolean
    activeCollection: string | null
    openedGallery: Gallery | null
    openedCollection: Collection | null
    openedEntry: GalleryEntry | null
    collapsed: boolean
    menuToggled: boolean
    loadProgress?: LoadProgress
}

const initialState: AppState = {
    autoScrolling: false,
    activeCollection: null,
    openedGallery: null,
    openedCollection: null,
    openedEntry: null,
    collapsed: !isDesktop || window.innerWidth < 1260,
    menuToggled: false
}

export interface OpenEntryPayload {
    gallery: Gallery
    collection: Collection
    entry: GalleryEntry
}

export interface ActivatePayload {
    gallery: Gallery
    collection?: Collection
}

export const appSlice = createSlice({
    name: "gallery",
    initialState,
    reducers: {
        activateMenu: (state, action: PayloadAction<ActivatePayload>) => {
            if (state.autoScrolling) {
                return state
            }
            const collection = action.payload.collection
                ? getCollectionId(action.payload.gallery, action.payload.collection)
                : getCollectionId(action.payload.gallery)
            if (state.activeCollection !== collection) {
                return {...state, activeCollection: collection}
            }
        },
        scrollFinished: (state) => {
            return {...state, autoScrolling: false}
        },
        selectMenu: (state, action: PayloadAction<ActivatePayload>) => {
            let res = {...state, autoScrolling: true}
            const collection = action.payload.collection
                ? getCollectionId(action.payload.gallery, action.payload.collection)
                : getCollectionId(action.payload.gallery)
            if (state.activeCollection !== collection) {
                res = {...res, activeCollection: collection}
            }
            return res
        },
        openEntry: (state, action: PayloadAction<OpenEntryPayload>) => {
            return {
                ...state,
                openedGallery: action.payload.gallery,
                openedEntry: action.payload.entry,
                openedCollection: action.payload.collection
            }
        },
        nextEntry: (state) => {
            if (!state.openedCollection || !state.openedEntry) return state
            const idx = state.openedCollection.entries.map((e: GalleryEntry) => e.thumbnailPath).indexOf(state.openedEntry.thumbnailPath)
            let entry
            if (idx === state.openedCollection.entries.length - 1) {
                entry = state.openedCollection.entries[0]
            } else {
                entry = state.openedCollection.entries[idx + 1]
            }
            return {
                ...state,
                openedEntry: entry
            }
        },
        previousEntry: (state) => {
            if (!state.openedCollection || !state.openedEntry) return state
            const idx: number = state.openedCollection.entries.map((e: GalleryEntry) => e.thumbnailPath).indexOf(state.openedEntry.thumbnailPath)
            let entry: GalleryEntry
            if (idx === 0) {
                entry = state.openedCollection.entries[state.openedCollection.entries.length - 1]
            } else {
                entry = state.openedCollection.entries[idx - 1]
            }
            return {
                ...state,
                openedEntry: entry
            }
        },
        closeEntry: state => {
            return {...state, openedEntry: null, openedGallery: null, openedCollection: null}
        },
        setCollapsed: (state, action: PayloadAction<boolean>) => {
            return {...state, collapsed: action.payload}
        },
        toggleMenu: (state, action: PayloadAction<boolean|undefined>) => {
            return {...state, menuToggled: action.payload === undefined ? !state.menuToggled : action.payload}
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            return {...state, loadProgress: {total: action.payload, loaded: 0}}
        },
        incrementLoadedCount: state => {
            if (!state.loadProgress) return
            return {...state, loadProgress: {...state.loadProgress, loaded: state.loadProgress.loaded+1}}
        }
    }
})

export const {
    activateMenu,
    selectMenu,
    scrollFinished,
    openEntry,
    nextEntry,
    previousEntry,
    closeEntry,
    setCollapsed,
    toggleMenu,
    setTotalCount,
    incrementLoadedCount
} = appSlice.actions

export const selectActiveCollection = (state: RootState) => state.app.activeCollection
export const selectAutoScrolling = (state: RootState) => state.app.autoScrolling
export const selectOpenedEntry = (state: RootState) => state.app.openedEntry
export const selectCollapsed = (state: RootState) => state.app.collapsed
export const selectMenuToggled = (state: RootState) => state.app.menuToggled
export const selectLoadProgress = (state: RootState) => state.app.loadProgress

export default appSlice.reducer