import React from "react";
import {Gallery, GalleryResult} from "../../model/gallery";
import MenuItem from "./menuItem";
import {useAppSelector} from "../../reducer/hooks";
import {selectActiveCollection} from "../../reducer/gallerySlice";

type NavigationProps = GalleryResult

const Navigation: React.FC<NavigationProps> = ({galleries}) => {

    const activeCollection = useAppSelector(selectActiveCollection)

    return (
        <nav>
            <div className="navbar">
                {galleries && galleries.map((g: Gallery)=><MenuItem key={g.type} gallery={g} activeCollection={activeCollection}/>)}
            </div>
        </nav>
    )
}

export default Navigation