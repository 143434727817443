import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {GalleryResult} from "../model/gallery";


export const galleriesApi = createApi({
    reducerPath: "galleriesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
    }),
    endpoints: (build) => ({
        getGalleries: build.query<GalleryResult, string>({
            query: () => "galleries",
        })
    })
})

const {useGetGalleriesQuery} = galleriesApi

export const useGetGalleries = () => useGetGalleriesQuery("")