export type GalleryResult = {
    galleries: Array<Gallery>
}

type LabeledAndTyped = {
    type: string,
    label: string
}

export type Gallery = LabeledAndTyped & {
    collections: Array<Collection>
}

export type Collection = LabeledAndTyped & {
    entries: Array<GalleryEntry>
}

export type GalleryEntry = {
    thumbnailPath: string,
    path: string,
    toggledPath?: string,
    title: string,
    alt: string,
    desc: string,
    logo?: string,
    noteTitle?: string,
    note?: string,
    noteBadge?: string
}

export const getCollectionId = (gallery: Gallery, collection?: Collection) => {
    return `${gallery.type}-${collection?.type ?? gallery.collections[0].type}`
}

export type LoadProgress = {
    total: number
    loaded: number
}