import React from "react";
import "./footer.scss"

const Footer: React.FC = () => {
    return (
        <footer>
            <div className="container">
                © Full Doors 2021
            </div>
        </footer>
    );
}

export default Footer