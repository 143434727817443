import React, {useState} from "react";
import {Collection, Gallery, GalleryEntry} from "../../model/gallery"
import {incrementLoadedCount, openEntry} from "../../reducer/gallerySlice";
import {useAppDispatch} from "../../reducer/hooks";
import "./entry.scss"

type EntryProps = {
    gallery: Gallery
    collection: Collection
    entry: GalleryEntry
}

const GalleryEntryContainer: React.FC<EntryProps> = ({gallery, collection, entry}) => {

    const dispatch = useAppDispatch()
    const [available, setAvailable] = useState<boolean>(true)

    const handleOpen = (e: React.MouseEvent) => {
        e.preventDefault()
        dispatch(openEntry({gallery, collection, entry}))
    }

    const handleLoad = () => {
        dispatch(incrementLoadedCount())
    }

    const handleError = () => {
        console.error("Failed to load", entry.thumbnailPath)
        dispatch(incrementLoadedCount())
        setAvailable(false)
    }

    if (!available) return null

    return (
        <a href={`${process.env.REACT_APP_BACKEND_URL}${entry.path}`} onClick={handleOpen} onMouseDown={(e)=>{e.preventDefault()}} target="_blank"
           rel="noreferrer" className={"lightboxgallery-gallery-item"}>
            <div>
                <img src={`${process.env.REACT_APP_BACKEND_URL}${entry.thumbnailPath}`} title={entry.title}
                     alt={entry.alt} onLoad={handleLoad} onError={handleError}/>
                <div className={"lightboxgallery-gallery-item-content"}>
                    <span className={"lightboxgallery-gallery-item-title"}>{entry.title}</span>
                </div>
                {entry.note && (
                    <span className={"fa-stack fa-2x badge"} data-badge-type={entry.note}>
                        <span className={"fas fa-certificate fa-stack-1x"}/>
                        <span className={"fa fa-stack-1x label"}>{entry.noteBadge}</span>
                    </span>
                )}
            </div>
        </a>
    )
}

export default GalleryEntryContainer