import React, {useEffect, useMemo, useState} from "react";
import {GalleryResult} from "../../model/gallery";
import "./header.scss"
import Logo from "./logo";
import Navigation from "../menu/nav";
import {useAppDispatch, useAppSelector} from "../../reducer/hooks";
import {selectCollapsed, selectMenuToggled, setCollapsed, toggleMenu} from "../../reducer/gallerySlice";
import {useDoubleTap} from "use-double-tap";
import DarkModeToggle from "./darkModeToggle";
import useMediaQuery from "../../hooks/useMediaQuery";
import {isDesktop} from "react-device-detect";

type NavBarProps = GalleryResult

const Header: React.FC<NavBarProps> = ({galleries}) => {


    const collapsed = useAppSelector(selectCollapsed)
    const menuToggled = useAppSelector(selectMenuToggled)
    const dispatch = useAppDispatch()
    const isNarrow = useMediaQuery("(max-width: 1260px)")

    const [headerClasses, setHeaderClasses] = useState<string | undefined>()
    const [backdropClasses, setBackdropClasses] = useState<string | undefined>()
    const doubleTap = useDoubleTap((event)=>{
        event.preventDefault()
        window.scrollTo({top: 0, behavior: "smooth"})
    })
    const doubleTapProps = useMemo(()=>{
        if (collapsed && !menuToggled) {
            return doubleTap
        }
        return {}
    }, [collapsed, menuToggled, doubleTap])

    const toggleMenuHandler = (e: React.MouseEvent | React.TouchEvent) => {
        if (collapsed) {
            e.preventDefault()
            dispatch(toggleMenu())
        }
    }

    useEffect(()=>{
        if (menuToggled) {
            document.body.classList.add("menu-open")
        } else {
            document.body.classList.remove("menu-open")
        }
    }, [menuToggled])

    useEffect(() => {
        dispatch(setCollapsed(!isDesktop || isNarrow))
        if (!isDesktop || isNarrow) {
            document.body.classList.add("collapsed")
        } else {
            document.body.classList.remove("collapsed")
            dispatch(toggleMenu(false))
        }
    }, [dispatch, isNarrow])

    useEffect(() => {
        if (collapsed) {
            setHeaderClasses(menuToggled ? "toggled" : undefined)
            setBackdropClasses(menuToggled ? "menu-backdrop" : "menu-backdrop hide")
        }
        return () => {
            setHeaderClasses(undefined)
            setBackdropClasses(undefined)
        }
    }, [menuToggled, collapsed])

    return (
        <>
            {collapsed && <div className={backdropClasses} onClick={toggleMenuHandler}/>}
            <header className={headerClasses} {...doubleTapProps}>
                <a href="//full.co.hu" title="full.co.hu">
                    <Logo/>
                </a>
                <Navigation galleries={galleries}/>
                <DarkModeToggle/>
                <button className="menu-toggle" onClick={toggleMenuHandler}>
                    <div className="icon"/>
                </button>
            </header>
        </>
    );
};


export default Header

